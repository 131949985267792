import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Layout, Card, Button, theme, Space, Modal, Spin, ConfigProvider, Avatar, Dropdown,Checkbox, Input } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import ButtonCategories from './components/ButtonCategories/ButtonCategories';
import { api } from "./config/api";
const { Header, Content, Footer } = Layout;
const { Meta } = Card;
const { TextArea } = Input;

const App = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [modalProductVisible, setModalProductVisible] = useState(false);
  const [selectedCakeId, setSelectedCakeId] = useState(null);
  const [selectedCakeDetails, setSelectedCakeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);

  const selectedProductCategoryUuid = useSelector(
    (state) => state.productCategorySelected
  );

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const items = [
    {
      label: '1st menu item',
      key: '1',
    },
    {
      label: '2nd menu item',
      key: '2',
    },
    {
      label: '3rd menu item',
      key: '3',
      danger: true,
    },
    {
      label: '4rd menu item',
      key: '4',
      danger: true,
      disabled: true,
    },
  ];

  useEffect(() => {

    if (selectedProductCategoryUuid) {
      setLoading(true); // Set loading to true when button is clicked

      // Your API endpoint URL goes here
      // Fetch data from the API
      fetch(`${api.host}/products/category/${selectedProductCategoryUuid}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Set loading to false even on error
        });

      // Fetch data from the API
      fetch(`${api.host}/categories/${selectedProductCategoryUuid}`, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setCategory(data);
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [selectedProductCategoryUuid]);

  useEffect(() => {

    if (selectedProductCategoryUuid === false) {
      setLoading(true); // Set loading to true when button is clicked

      // Your API endpoint URL goes here
      const apiUrl = `${api.host}/products/all`; // Update with your API endpoint

      // Fetch data from the API
      fetch(apiUrl, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
          setLoading(false); // Set loading to false
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Set loading to false even on error
        });
    }
  }, [selectedProductCategoryUuid]);

  const handleProductClick = (cakeId) => {
    setSelectedCakeId(cakeId);
    // Simulate API call to fetch cake details
    setTimeout(() => {
      const selectedCake = products.find(cake => cake.uuid === cakeId);
      setSelectedCakeDetails(selectedCake);
      setModalProductVisible(true);
    }, 500); // Simulate delay for API response
  };

  return (

    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: '#EC4444',
          borderRadius: 2,

          // Alias Token
          colorBgContainer: '#fff',
        },
      }}
    >

      <Layout>
        <Header
          style={{
            background: '#EC4444',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            textAlign: "center"
          }}
        >
          <img src='/img/cake.png' style={{ width: 16, marginRight: 10 }} /> <a href='/' style={{ color: "#fff" }}><h4 style={{ margin: 0, display: 'inline' }}>Torty</h4></a>
          <div style={{ textAlign: "center", width: "100%", marginTop: "25px", }}>




            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="65.000000pt" height="225.000000pt" viewBox="0 0 225.000000 225.000000"
              preserveAspectRatio="xMidYMid meet">

              <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                fill="#cf3630" stroke="none">
                <path d="M1127 1660 c-8 -20 -17 -28 -27 -25 -8 2 -22 0 -30 -5 -13 -9 -12
-12 4 -25 11 -8 26 -15 33 -15 7 0 13 -9 13 -19 0 -22 -37 -38 -52 -23 -7 7
-16 7 -28 0 -21 -13 -50 -4 -50 16 0 8 9 18 21 21 16 5 18 9 8 21 -7 8 -22 14
-34 12 -12 -1 -30 5 -40 15 -28 28 -29 13 -1 -31 13 -22 31 -55 39 -75 15 -34
16 -35 54 -26 49 11 177 11 225 0 l38 -9 31 62 c17 33 38 69 47 79 20 22 -1
22 -24 0 -9 -10 -27 -16 -39 -15 -12 2 -27 -4 -34 -12 -10 -12 -8 -16 9 -21
13 -4 20 -13 18 -23 -3 -13 -15 -17 -53 -18 -28 0 -56 4 -62 9 -22 16 -14 35
18 42 40 9 37 41 -4 37 -21 -2 -27 2 -27 16 0 19 -18 42 -33 42 -5 0 -14 -13
-20 -30z"/>
                <path d="M320 1413 c27 -10 30 -26 30 -160 0 -106 -3 -135 -16 -147 -14 -15
-12 -16 22 -16 33 0 36 2 21 13 -15 11 -17 29 -15 147 l3 135 54 -142 c30 -79
58 -143 61 -143 4 0 29 60 55 133 27 72 52 136 57 142 4 5 8 -52 8 -126 0 -92
-4 -139 -12 -147 -9 -9 1 -12 48 -11 32 0 53 3 47 6 -10 3 -13 44 -13 154 0
146 1 150 23 159 17 7 7 9 -40 9 l-62 1 -21 -57 c-11 -32 -28 -78 -37 -103 -9
-25 -19 -48 -22 -52 -4 -4 -24 42 -46 102 l-40 110 -60 -1 c-33 0 -53 -3 -45
-6z"/>
                <path d="M873 1404 c-50 -132 -113 -279 -126 -295 -16 -18 -15 -19 25 -19 37
0 40 1 24 13 -15 11 -17 19 -9 43 9 27 14 29 64 32 51 3 56 1 67 -23 15 -33
15 -52 0 -58 -7 -3 16 -6 51 -6 61 -1 63 0 48 17 -9 9 -41 82 -72 162 -31 79
-58 146 -60 149 -3 2 -8 -5 -12 -15z"/>
                <path d="M1077 1398 c11 -13 39 -54 61 -92 l41 -70 -43 -60 c-24 -34 -54 -67
-67 -73 -22 -12 -20 -13 25 -13 42 0 47 2 36 15 -11 13 -7 24 21 65 33 49 34
49 50 29 8 -11 24 -36 34 -56 16 -29 17 -36 4 -44 -9 -6 10 -9 55 -9 58 0 67
2 54 13 -9 6 -39 50 -67 96 l-50 84 45 58 c25 32 52 63 62 68 13 8 4 10 -37
11 -50 0 -53 -1 -35 -14 19 -14 19 -15 0 -50 -32 -61 -41 -62 -70 -13 -31 52
-32 64 -8 70 9 3 -16 5 -57 6 l-74 1 20 -22z"/>
                <path d="M1523 1404 c-50 -132 -113 -279 -126 -295 -16 -18 -15 -19 25 -19 37
0 40 1 24 13 -15 11 -17 19 -9 43 9 27 14 29 64 32 51 3 56 1 67 -23 15 -33
15 -52 0 -58 -7 -3 16 -6 51 -6 61 -1 63 0 48 17 -9 9 -41 82 -72 162 -31 79
-58 146 -60 149 -3 2 -8 -5 -12 -15z"/>
                <path d="M1710 1413 c34 -12 40 -36 40 -158 0 -93 -4 -126 -16 -143 -15 -22
-14 -22 22 -22 33 0 36 2 21 13 -15 11 -17 28 -15 132 l3 119 100 -138 100
-138 5 162 c5 151 6 164 25 171 13 5 1 7 -35 7 -30 0 -47 -2 -37 -5 10 -3 22
-16 28 -29 11 -30 12 -164 2 -164 -5 0 -39 45 -77 100 l-70 100 -55 -1 c-31 0
-49 -3 -41 -6z"/>
                <path d="M908 1000 c-226 -18 -484 -69 -599 -118 l-47 -20 40 -114 c22 -62 43
-116 46 -120 4 -3 48 5 97 18 183 50 469 84 700 84 234 0 531 -36 701 -86 45
-13 88 -25 95 -26 19 -4 103 233 86 244 -24 16 -203 70 -292 88 -225 47 -587
69 -827 50z"/>
                <path d="M198 760 c-31 -16 -59 -34 -62 -39 -3 -5 16 -26 43 -46 52 -37 54
-43 63 -150 3 -35 -4 -36 124 16 86 35 101 44 97 60 -8 28 -16 30 -69 14 -46
-14 -64 -13 -64 5 0 16 -62 170 -68 170 -4 -1 -33 -14 -64 -30z"/>
                <path d="M2000 699 c-18 -49 -36 -92 -41 -95 -5 -3 -32 2 -60 10 -55 17 -52
18 -63 -32 -1 -2 44 -21 99 -43 55 -22 106 -43 113 -46 8 -4 12 6 12 33 0 89
10 111 61 146 27 18 49 36 49 40 0 4 -31 23 -69 42 l-69 35 -32 -90z"/>
              </g>
            </svg>

          </div>
          <a href='/kontakt' style={{ color: "#fff" }}><h4 style={{ margin: 0, display: 'inline' }}>Kontakt</h4></a>

        </Header>

        <ButtonCategories></ButtonCategories>


        <Layout>

          <Layout>


            <Content
              className="site-layout-background"
              style={{
                padding: 10,
                margin: 10,
                marginTop: 20,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >

              <Card className='header'>
                <div style={{ textAlign: "center" }}>
                  {category.length == 0 ? (
                    <>
                      <h1>Oferta Piekarni Maxan</h1>
                      <p>Poznaj naszą pasję w tworzeniu niezrównanych tortów, które są nie tylko pełne aromatów, ale także wolne od sztucznych konserwantów.</p>
                      <h3>Co mówią nasi klienci?</h3>
                      <Card style={{ overflowY: 'hidden', overflowX: 'scroll' }}>
                        <Space size={30}>
                          <p className='small'>
                            <Avatar size={'large'} src='https://scontent-waw2-1.xx.fbcdn.net/v/t31.18172-1/14468426_546991105492081_6460373036583596733_o.jpg?stp=c0.10.32.32a_cp0_dst-jpg_p32x32&_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=CsMposiNrxwAb6lJd5I&_nc_ht=scontent-waw2-1.xx&cb_e2o_trans=q&oh=00_AfDea1pjwAi0seZHZeZ9FmqNlPgELXsYxPoRAwpbJFkUhg&oe=663680F0'>
                            </Avatar>
                            <i>Ooooooo maaaatko i jak tu o linię dbać!!!?????!!!!</i> - Elżbieta
                          </p>
                          <p className='small'>
                            <Avatar size={'large'} src='https://scontent-waw2-1.xx.fbcdn.net/v/t39.30808-1/356118286_6417357914996220_3801313959312430670_n.jpg?stp=cp0_dst-jpg_p32x32&_nc_cat=107&ccb=1-7&_nc_sid=5f2048&_nc_ohc=bOEbH0g2MikAb5iAvhu&_nc_ht=scontent-waw2-1.xx&cb_e2o_trans=q&oh=00_AfDfWDisHEmj39hcTE4QZi4x7WAndRsN9xjkx2C6zZAzvw&oe=6614FC15'>
                            </Avatar>
                            <i>Porzeczkowa rozkosz😋😍</i> - Małgorzata
                          </p>
                          <p className='small'>
                            <Avatar size={'large'} src='https://scontent-waw2-1.xx.fbcdn.net/v/t39.30808-1/371026133_6463448313704475_7110637170019083462_n.jpg?stp=cp0_dst-jpg_p32x32&_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=KLQBEmkM2hcAb63TnWT&_nc_ht=scontent-waw2-1.xx&cb_e2o_trans=q&oh=00_AfAuFFd3cHMStczmc-Gv-co8ZdbERBwKnV2897t7q5mX9w&oe=6614CBB0'>
                            </Avatar>
                            <i>Pychota 😋 idealne na małego głoda 😁</i> - Małgorzata
                          </p>
                        </Space>
                        <p>Oraz dziesiątki <a href='https://www.facebook.com/piekarnia.maxan'>innych komentarzy 😍</a></p>
                      </Card>
                    </>
                  ) : (
                    <>
                      <h1>{category.name}</h1>
                    </>
                  )}
                </div>


              </Card>
              <br />
              <Spin spinning={loading}>
                <div id='gridContainer'
                >

                  {products.map(cake => (
                    <Card
                      onClick={() => handleProductClick(cake.id)}
                      key={cake.id}
                      hoverable
                      style={{ maxWidth: '100%', marginBottom: '30px', padding: 0 }}
                      cover={<img alt={cake.name} src={cake.photo} />}
                      actions={[
                        <Button onClick={() => handleProductClick(cake.id)}>
                          Zobacz
                        </Button>
                      ]}
                    >
                      <Space direction='vertical'>
                        <a href='' onClick={() => handleProductClick(cake.id)}><h2><u>{cake.name}</u></h2></a>
                        <Meta description={cake.description} />
                        <Space direction='vertical'>
                          <h3>{cake.price} ZŁ</h3>
                        </Space>
                      </Space>
                    </Card>
                  ))}

                </div>
              </Spin>
            </Content>
          </Layout>
        </Layout>
        <Footer style={{ textAlign: 'center' }}>
          ©{new Date().getFullYear()} Piekarnia Maxan
        </Footer>

        <Modal
          visible={modalProductVisible}
          onCancel={() => setModalProductVisible(false)}
          footer={null}
        >
          {products.slice(0, 1).map(cake => (
            <Card
              key={cake.id}
              hoverable
              style={{ maxWidth: '100%', marginBottom: '30px', padding: 0 }}
              cover={<img alt={cake.name} src={cake.photo} />}
              actions={[
                <Button onClick={() => handleProductClick(cake.id)} icon={<ShoppingCartOutlined />}>
                  Zamawiam
                </Button>
              ]}
            >

              <div>
                <Space  style={{width:'100%'}} direction='vertical'>
                  <a href=''><h3><u>{cake.name}</u></h3></a>
                  <Meta description={cake.description} />
                  <Space direction='vertical'>
                    <h4>{cake.price} ZŁ</h4>
                  </Space>


                  <Card>
                    <Space direction='vertical'>
                      <Space>
                      <Dropdown.Button>
                        Wybierz smak
                      </Dropdown.Button>
                      <Dropdown.Button>
                        Wybierz kolor
                      </Dropdown.Button>
                      </Space>
                      <Checkbox onChange={onChange}>Czy dodać figurkę?</Checkbox>
                      <Checkbox onChange={onChange}>Czy dodać napis?</Checkbox>
                      <Dropdown.Button>
                        Wybierz miejsce napisu
                      </Dropdown.Button>
                      <TextArea rows={4} placeholder="Wpisz tekst na tort" maxLength={6} />
                      <br/>
                      <TextArea rows={4} placeholder="Dodatkowe uwagi do zamówienia" />
                      <Input placeholder="Numer telefonu" />
                      <Dropdown.Button>
                        Wybierz sklep do odbioru
                      </Dropdown.Button>
                    </Space>
                  </Card>

                </Space>
              </div>


            </Card>
          ))}
        </Modal>
      </Layout>

    </ConfigProvider>


  );
};

export default App;
