import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip, FloatButton, Modal, Menu, Spin } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { api } from "../../config/api";

const ButtonCategories = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleShowContextMenu = () => {
    setModalVisible(true);
    setLoading(true); // Set loading to true when button is clicked

    // Your API endpoint URL goes here
    const apiUrl = `${api.host}/categories/group/1`; // Update with your API endpoint

    // Fetch data from the API
    fetch(apiUrl, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false even on error
      });
  };

  const handleContextMenuAction = (action) => {
    console.log("Context menu action:", action);
    dispatch({
      type: "productCategorySelected",
      payload: {
        categoryUuid: action,
      },
    });
    setModalVisible(false);
  };

  return (
    <>
      <Tooltip title="Rodzaje dostępnych tortów" trigger="click" defaultOpen>
        <FloatButton
          type="primary"
          icon={<MenuOutlined />}
          shape="square"
          style={{
            width: "200px",
            textTransform: "uppercase",
            fontSize: "80%",
            position: "fixed",
            top: "90%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            insetInlineEnd: "auto",
            insetBlockEnd: "auto",
          }}
          onClick={() => handleShowContextMenu()}
        />
      </Tooltip>
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <h2>Jakiego rodzaju tortów poszukujesz?</h2>
        <Spin spinning={loading}>
          <Menu onClick={({ key }) => handleContextMenuAction(key)}>
            {categories.map((category) => (
              <Menu.Item key={category.uuid}>{category.name}</Menu.Item>
            ))}
          </Menu>
        </Spin>
      </Modal>
    </>
  );
};

export default ButtonCategories;
